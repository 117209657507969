import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 90px;
  border-bottom: 1px solid #d5dce3;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;

  @media (min-width: 1024px) {
    justify-content: space-between;
    padding-right: 100px;
  }
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1024px) {
    padding-left: 84px;
  }
`;

const HeaderTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  color: #0091ff;
  margin-bottom: 0;
  margin-top: 0;
`;

const Header = () => (
  <Container>
    <HeaderTitleContainer>
      <HeaderTitle>Redefinir senha</HeaderTitle>
    </HeaderTitleContainer>
  </Container>
);

export default Header;
